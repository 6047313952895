<template>
	<div style="height: 100%;">
		<main-table>
			<el-form slot="header" :inline="true" :model="params" :size="theme.size">
				<el-form-item >
					<el-input v-model="params.keyword" placeholder="职位/姓名/手机/邮箱/最近工作单位/备注说明" clearable></el-input>
				</el-form-item>
				<el-form-item v-if="!admin_user.shop_id">
					<el-select v-model="params.shop_id" placeholder="城市站点" filterable multiple collapse-tags>
						<el-option v-for="shop in shops" :key="shop.id" :label="shop.name" :value="shop.id"></el-option>
					</el-select>
				</el-form-item>
				<el-form-item>
					<el-select v-model="params.employees" placeholder="请选择邀请人" filterable multiple collapse-tags>
						<el-option v-for="employee in employees" :key="employee.id" :label="employee.name" :value="employee.id"></el-option>
					</el-select>
				</el-form-item>
				<el-form-item>
					<el-select v-model="params.source" placeholder="来源渠道" filterable multiple collapse-tags>
						<el-option v-for="(source, s) in fields.source" :key="s" :label="source" :value="s"></el-option>
					</el-select>
				</el-form-item>
				<el-form-item>
					<el-select v-model="params.status" placeholder="状态" filterable multiple collapse-tags>
						<el-option v-for="(status, s) in fields.status" :key="s" :label="status" :value="s"></el-option>
					</el-select>
				</el-form-item>
				<el-form-item>
					<el-button type="primary" @click="getCandidates({ ...params, page: 1})">查询</el-button>
				</el-form-item>
			</el-form>
			<el-table slot="table" ref="position_table" class="bill scroll-wrapper absolute" height="100%" :data="candidates" :size="theme.size">
				<el-table-column label="邀请人" min-width="90">
					<template slot-scope="scope">
						<p>{{scope.row.employee.name}}</p>
						<p v-if="!admin_user.shop_id">{{scope.row.employee.shop ? scope.row.employee.shop.name : '未知'}}</p>
					</template>
				</el-table-column>
				<el-table-column label="应聘职位" prop="recruitment.title" min-width="100"></el-table-column>
				<el-table-column label="来源渠道" min-width="80" :formatter="({source}) => { return fields.source[source]; }"></el-table-column>
				<el-table-column label="姓名" min-width="70">
					<el-link slot-scope="scope" type="primary" :disabled="!$utils.update($api.URI_POSITIONS_CANDIDATES)" @click="$refs['show_dialog'].open(scope.row)">{{scope.row.name}}</el-link>
				</el-table-column>
				<el-table-column label="性别" min-width="50" :formatter="({gender}) => { return fields.gender[gender] || '/'; }"></el-table-column>
				<el-table-column label="联系方式" min-width="120">
					<template slot-scope="scope">
						<p v-if="scope.row.mobile">{{scope.row.mobile}}</p>
						<p v-if="scope.row.mail">{{scope.row.mail}}</p>
					</template>
				</el-table-column>
				<el-table-column label="工龄/年龄" min-width="80" :formatter="({age, work_years}) => { return work_years + ' / ' + age; }"></el-table-column>
				<el-table-column label="最近工作单位" prop="nearest" min-width="150"></el-table-column>
				<el-table-column label="备注说明" prop="remarks" min-width="120" show-overflow-tooltip></el-table-column>
				<el-table-column label="状态" min-width="70">
					<el-link slot-scope="scope" type="primary" @click="$refs['show_dialog'].onStatus(scope.row)">{{fields.status[scope.row.status]}}</el-link>
				</el-table-column>
				<template  v-if="theme.width >= 1450">
					<el-table-column label="更新时间" prop="updated_at" min-width="150"></el-table-column>
					<el-table-column label="创建时间" prop="created_at" min-width="150"></el-table-column>
				</template>
				<el-table-column label="操作" width="60">
					<el-button slot="header" type="text" size="mini" @click="$refs['show_dialog'].open()" :disabled="!$utils.create($api.URI_POSITIONS_CANDIDATES)">添加</el-button>
					<template slot-scope="scope">
						<el-popconfirm title="确定恢复这个职位吗？" @confirm="handleCandidate({action: 'restore', id: scope.row.id})" v-if="scope.row.deleted_at">
							<el-button slot="reference" type="text" :disabled="!$utils.restore($api.URI_POSITIONS_CANDIDATES)">恢复</el-button>
						</el-popconfirm>
						<el-popconfirm title="确定删除这个职位吗？" @confirm="onDelete(scope.row)" v-else>
							<el-button slot="reference" type="text" :disabled="!$utils.delete($api.URI_POSITIONS_CANDIDATES)">删除</el-button>
						</el-popconfirm>
					</template>
				</el-table-column>
			</el-table>
			<el-pagination
				slot="footer"
				:layout="$config.PAGE_LAYOUT"
				:page-sizes="$config.PAHE_SIZES"
				:page-size="params.perPage"
				:current-page="params.page"
				:total="params.total"
				@size-change="(v) => { getCandidates({ ...params, page: 1, perPage: v }) }"
				@current-change="(v) => { getCandidates({ ...params, page: v }) }"
				background>	
			</el-pagination>
		</main-table>
		<show-position ref="show_dialog" :fields="fields" :recruitments="recruitments" @refresh="getCandidates(params)"></show-position>
	</div>
</template>

<script>
	import { mapState } from 'vuex';
	import showPosition from './show';
	import mainTable from '@/components/main-table';

	export default {
		components: {
			mainTable,
			showPosition
		},
		computed: {
			...mapState(['theme', 'admin_user', 'models']),
			fields () {
				return this.models.candidates || {};
			},
			shops () {
				return this.models.shops ? this.models.shops.data : [];
			}
		},
		methods: {
			async getCandidates (params, loading = false) {
				const res = await this.$http.get(this.$api.URI_POSITIONS_CANDIDATES, {params, headers: { loading }});
				const { code, msg, result } = res.data;
				if (code != 0) return this.$message.error(msg);
				this.candidates = result.data;
				this.recruitments = result.recruitments;
				this.employees = result.employees;
				this.params = {
					...params,
					total: result.total,
					perPage: parseInt(result.per_page)
				}
			},
			async handleCandidate (params) {
				const res = await this.$http.get(this.$api.URI_POSITIONS_CANDIDATES, {params, headers: {loading: true}});
				const { code, msg } = res.data;
				if (code != 0) return this.$message.error(msg);
				this.getCandidates(this.params);
				this.$message.success(msg);
			},
			onDelete (r) {
				if (r.employees_count) return this.$message.error(`还有 ${r.employees_count} 人在使用`);
				this.handleCandidate({action: 'delete', id: r.id});
			}
		},
		data() {
			return {
				candidates: [],
				employees: [],
				recruitments: [],
				params: {
					perPage: 10,
				}
			}
		},
		created () {
			this.getCandidates(this.params, true);
		}
	};
</script>