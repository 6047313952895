<template>
	<el-dialog width="460px" :title="candidate.id ? (read_only ? '应聘详情' : '编辑应聘') : '创建应聘'" :visible.sync="show_dialog" :fullscreen="theme.width < 680" :close-on-click-modal="false" append-to-body destroy-on-close>
		<div style="position: relative;" v-if="read_only">
			<el-descriptions style="margin-top: -20px;" :column="2" :size="theme.size" border>
				<el-descriptions-item label="邀请人">{{candidate.employee.name}}</el-descriptions-item>
				<el-descriptions-item label="应聘">{{candidate.recruitment.title}}</el-descriptions-item>
				<el-descriptions-item label="姓名">{{candidate.name}}</el-descriptions-item>
				<el-descriptions-item label="姓别">{{fields.gender[candidate.gender] || '/'}}</el-descriptions-item>
				<el-descriptions-item label="手机">{{candidate.mobile}}</el-descriptions-item>
				<el-descriptions-item label="年龄">{{candidate.age}}</el-descriptions-item>
				<el-descriptions-item label="邮箱">{{candidate.mail || '/'}}</el-descriptions-item>
				<el-descriptions-item label="累计工作">{{candidate.work_years}} 年</el-descriptions-item>
				<el-descriptions-item label="创建时间">{{candidate.created_at}}</el-descriptions-item>
				<el-descriptions-item label="来源渠道">{{fields.source[candidate.source] || '/'}}</el-descriptions-item>
				<el-descriptions-item label="最近单位" :span="2">{{candidate.nearest}}</el-descriptions-item>
				<el-descriptions-item label="备注说明" :span="2">{{candidate.remarks}}</el-descriptions-item>
				<el-descriptions-item label="简历作品" :span="2">
					<div class="e-attachments" v-if="candidate.attachments.length">
						<p v-for="attachment in candidate.attachments" @click="openFile(attachment.url)" :key="attachment.id">
							<i class="el-icon-document"></i> <span>{{attachment.name}}</span>
						</p>
					</div>
				</el-descriptions-item>
			</el-descriptions>
			<div style="color: rgba(255, 0, 0, 0.2); position: absolute; top: 150px; left: 50%; font-size: 38px; font-weight: bold; transform: translate(-50%, -50%) rotateZ(20deg);" v-if="fields.status[candidate.status]">{{fields.status[candidate.status]}}</div>
		</div>
		<el-form label-width="90px" ref="candidate_create" :model="candidate" :rules="candidate_rules" :size="theme.size" v-else source-icon>
			<el-form-item label="姓名/姓别" style="margin-bottom: 0;" required>
				<el-col :span="11">
					<el-form-item prop="name">
						<el-input v-model="candidate.name" placeholder="请输入姓名" clearable></el-input>
					</el-form-item>
				</el-col>
				<el-col style="text-align: center;" :span="2">-</el-col>
				<el-col :span="11">
					<el-form-item prop="gender">
						<el-radio-group v-model="candidate.gender">
							<el-radio-button :label="1">男</el-radio-button>
							<el-radio-button :label="2">女</el-radio-button>
						</el-radio-group>
					</el-form-item>
				</el-col>
			</el-form-item>
			<el-form-item label="手机/邮箱" style="margin-bottom: 0;" required>
				<el-col :span="10">
					<el-form-item prop="mobile">
						<el-input v-model="candidate.mobile" placeholder="请输入手机" clearable></el-input>
					</el-form-item>
				</el-col>
				<el-col style="text-align: center;" :span="1">-</el-col>
				<el-col :span="13">
					<el-form-item prop="mail">
						<el-input v-model="candidate.mail" placeholder="请输入邮箱" clearable></el-input>
					</el-form-item>
				</el-col>
			</el-form-item>
			<el-form-item label="年龄/工龄" style="margin-bottom: 0;" required>
				<el-col :span="11">
					<el-form-item prop="age">
						<el-input-number style="width: 100%;" v-model="candidate.age" :min="16" :max="999" placeholder="年龄"></el-input-number>
					</el-form-item>
				</el-col>
				<el-col style="text-align: center;" :span="2">-</el-col>
				<el-col :span="11">
					<el-form-item prop="work_years">
						<el-input-number style="width: 100%;" v-model="candidate.work_years" :min="0" :max="999" placeholder="工龄"></el-input-number>
					</el-form-item>
				</el-col>
			</el-form-item>
			<el-form-item label="应聘/来源" style="margin-bottom: 0;" required>
				<el-col :span="11">
					<el-form-item prop="recruitment_id">
						<el-select v-model="candidate.recruitment_id" placeholder="请选择应聘" filterable clearable>
							<el-option v-for="recruitment in recruitments" :key="recruitment.id" :label="recruitment.title" :value="recruitment.id"></el-option>
						</el-select>
					</el-form-item>
				</el-col>
				<el-col style="text-align: center;" :span="2">-</el-col>
				<el-col :span="11">
					<el-form-item prop="source">
						<el-select v-model="candidate.source" placeholder="请选择来源" filterable clearable>
							<el-option v-for="(source, s) in fields.source" :key="s" :label="source" :value="+s"></el-option>
						</el-select>
					</el-form-item>
				</el-col>
			</el-form-item>
			<el-form-item label="最近单位" prop="nearest">
				<el-input v-model="candidate.nearest" placeholder="请输入最近工作单位" clearable></el-input>
			</el-form-item>
			<el-form-item label="备注说明" prop="remarks">
				<el-input v-model="candidate.remarks" type="textarea" placeholder="请输入备注说明。例如来源渠道聊天记录" rows="3" maxlength="255" show-word-limit clearable></el-input>
			</el-form-item>
			<el-form-item label="简历作品" prop="attachments">
				<el-upload
					list-type="picture"
					:action="$api.URI_UPLOADS"
					:data="{type: candidates_cfg.type}"
					:accept="candidates_cfg.ext.map(e => { return '.'+e; }).join(',')"
					:on-success="(r, f, l) => { onSuccess(l, 'attachments'); }"
					:on-preview="(f) => { openFile(f.url); }"
					:before-upload="beforeFeeAttach"
					:file-list="candidate.attachments">
					<el-button type="text" size="mini" icon="el-icon-upload">点击上传</el-button>
					<p slot="tip" style="font-size: 12px; color: #999; line-height: 1.5;">只能上传 {{candidates_cfg.ext.join('/').toUpperCase()}} 文件，且大小不超过 {{candidates_cfg.format_size_unit}}</p>
				</el-upload>
			</el-form-item>
			<el-form-item v-if="!read_only">
				<el-button type="primary" @click="submitForm('candidate_create')">{{candidate.id ? '提交保存' : '提交创建'}}</el-button>
			</el-form-item>
		</el-form>
	</el-dialog>
</template>

<script>
	import { mapState } from 'vuex';

	export default {
		computed: {
			...mapState(['theme']),
			candidates_cfg () {
				return this.$utils.uploadConfig('candidates');
			},
			read_only () {
				if (this.candidate.status == 2) return true;
				if (this.candidate.status == 4) return true;
				return false;
			}
		},
		props: {
			fields: {
				type: Object
			},
			recruitments: {
				type: Array
			}
		},
		methods: {
			openFile (u) {
				window.open(u);
			},
			async open (r) {
				this.candidate = {...r};
				this.show_dialog = true;
			},
			onSuccess (list) {
				this.candidate = {
					...this.candidate,
					attachments: list.map((file) => {
						const { response } = file;
						if (response) {
							const { status, id } = response.result;
							if (status == "success") {
								file.id = id;
							}
						}
						return file;
					})
				};
			},
			beforeFeeAttach (file) {
				const { size, format_size_unit } = this.insurances_cfg;
				const is = file.size < size;
				if (!is) return this.$message.error('上传相关简历作品文件不能超过 '+format_size_unit);
				return is;
			},
			async submitCandidates (data) {
				const res = await this.$http.post(this.$api.URI_POSITIONS_CANDIDATES, data);
				const { code, msg } = res.data;
				if (code != 0) return this.$message.error(msg);
				this.$message({
					type: 'success',
					duration: 1000,
					message: msg,
					onClose: () => {
						this.$emit('refresh');
						this.show_dialog = false;
					}
				});
			},
			submitForm (ref) {
				this.$refs[ref].validate((valid) => {
					if (!valid) return this.$message.error("提交表单有误！");
					switch (ref) {
						case 'candidate_create':
							this.submitCandidates(this.candidate);
						break;
					}
				});
			},
			onStatus (r) {
				const { id, name, status } = r;
				switch (status) {
					case 1:
					case 3:
						if (!this.$utils.create(this.$api.URI_APPROVALS)) return this.$message.error('暂无提交审核权限！');
						this.$confirm('应聘候选人 ' + name, '确定要提交审核吗？', {
							dangerouslyUseHTMLString: true,
							confirmButtonText: '确定',
							cancelButtonText: '取消',
							type: 'warning'
						}).then(async () => {
							const res = await this.$http.post(this.$api.URI_APPROVALS, {common_model_id: id, common_model_type: 'App\\Models\\Candidate'});
							const { code, msg: message } = res.data;
							if (code != 0) return this.$message.error(message);
							this.$message({
								type: 'success',
								duration: 1000,
								message,
								onClose: () => {
									this.$emit('refresh');
								}
							});
						});
					break;

					default:
						this.open(r);
					break;
				}
			}
		},
		data() {
			return {
				candidate: {},
				show_dialog: false,
				candidate_rules: {
					name: [{ required: true, message: '必须填写姓名', trigger: 'blur'}],
					mobile: [{ required: true, message: '必须填写手机号', trigger: 'blur'}],
					recruitment_id: [{ required: true, message: '必须选择应聘职位', trigger: 'blur'}],
					source: [{ required: true, message: '必须选择来源渠道', trigger: 'blur'}],
					age: [{ required: true, message: '必须填写年龄', trigger: 'blur'}],
					work_years: [{ required: true, message: '必须填写工龄', trigger: 'blur'}]
				}
			}
		}
	};
</script>